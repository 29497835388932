import { clamp } from "../../utils/math";

export const getVideoSize = async (url: string) =>
    await new Promise<[number, number]>((resolve, reject) => {
        const v = document.createElement('video');
        v.autoplay = false;
        v.muted = true;
        v.onerror = reject;
        v.onloadedmetadata = () => {
            resolve([v.videoWidth, v.videoHeight]);
            v.remove();
        };
        v.src = url;
    });

export const getVideoAspect = async (url: string) => {
    var size = await getVideoSize(url);
    return size[0] / size[1];
};

export const resizeVideoUrl = async (url: string, size: number): Promise<Blob> => {
    let resolve: ((blob: Blob | null) => void) | null = null;
    let reject: ((error?: unknown) => void) | null = null;

    const promise = new Promise<Blob | null>((res, rej) => {
        resolve = res;
        reject = rej;
    });

    const video: HTMLVideoElement = document.createElement('video');

    video.autoplay = false;
    video.muted = true;

    video.onloadedmetadata = () => {
        video.currentTime = clamp(5, 0, video.duration - 5);
    };

    video.onerror = (e: unknown) => {
        reject!(e);
    };

    video.onseeked = () => {
        var r = Math.min(1, size / video.videoWidth, size / video.videoHeight);

        const canvas = document.createElement('canvas');
        canvas.height = Math.round(video.videoHeight * r);
        canvas.width = Math.round(video.videoWidth * r);
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(resolve!, 'image/jpeg', 0.95);
    };

    video.src = url;

    const blob = await promise;

    if (!blob) throw new Error('Unable to obtain video frame for thumbnail');

    return blob;
};
