import { arrayOf } from '../../utils/array';
import { FileClip } from '../../utils/file';
import { inverse, mulH, toPoint } from '../../utils/mat3x3';
import { clamp, dif, int, mul, round } from '../../utils/math';
import { blobFromUrl } from './blockfromurl';
import { toInt } from './color';

const PALETTE_BLOCK = 51;
const SPACIAL_BLOCK = 0.1;

export const transformImgUrl = async (
    url: string,
    { H, aspect: clipAspect }: FileClip,
    size: number
): Promise<File | Blob> => {
    const clipWr = clipAspect > 1 ? 1 : clipAspect;
    const clipHr = clipAspect < 1 ? 1 : 1 / clipAspect;

    const img = document.createElement('img');

    img.src = url;

    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    const imgSize = Math.min(2500, Math.max(img.naturalWidth, img.naturalHeight));

    let destSize = Math.min(size, imgSize);
    let destW = round(destSize * clipWr);
    let destH = round(destSize * clipHr);

    const invH = inverse(H);
    const clipTop = toPoint(0, 0, destW, destH);
    const clipBot = toPoint(destW, destH, destW, destH);
    const topLeft = mulH(invH, clipTop);
    const botRight = mulH(invH, clipBot);
    const srcSizePt = mul(dif(botRight, topLeft), destSize);

    let srcSize = round(Math.max(srcSizePt.x, srcSizePt.y));

    if (srcSize > imgSize) {
        // this is not allowed...
        const rat = imgSize / srcSize;
        srcSize = imgSize;
        destSize = round(destSize * rat);
        destW = round(destW * rat);
        destH = round(destH * rat);
    }

    const aspect = img.naturalWidth / img.naturalHeight;
    const wr = aspect > 1 ? 1 : aspect;
    const hr = aspect < 1 ? 1 : 1 / aspect;

    const srcW = round(srcSize * wr);
    const srcH = round(srcSize * hr);
    const srcPadLeft = (srcSize - srcW) / 2;
    const srcPadTop = (srcSize - srcH) / 2;

    const canvas = document.createElement('canvas');
    canvas.width = srcW;
    canvas.height = srcH;

    const fullContext = canvas.getContext('2d');

    if (!fullContext) throw new Error('Unable to obtain image draw context');

    fullContext.drawImage(img, 0, 0, srcW, srcH);

    const data = fullContext.getImageData(0, 0, srcW, srcH);

    const srcChannels = data.data.length / (data.width * data.height);

    const resized = fullContext.createImageData(destW, destH, { colorSpace: 'srgb' });

    const destChannels = resized.data.length / (destW * destH);

    for (let y = 0; y < destH; y++) {
        const offsetY = y * (destW * destChannels);
        for (let x = 0; x < destW; x++) {
            const destOffset = offsetY + x * destChannels;

            // const {x:srcX, y:srcY} = normalMultiply(H, x, y, destSize, sourceSize)
            const srcPt = mulH(H, toPoint(x, y, destW, destH));

            const srcX = clamp(int((srcPt.x + 0.5) * srcSize - srcPadLeft), 0, srcW - 1);
            const srcY = clamp(int((srcPt.y + 0.5) * srcSize - srcPadTop), 0, srcH - 1);

            const srcOffset = srcY * (srcW * srcChannels) + srcX * srcChannels;

            resized.data[destOffset] = data.data[srcOffset];
            resized.data[destOffset + 1] = data.data[srcOffset + 1];
            resized.data[destOffset + 2] = data.data[srcOffset + 2];
            if (destChannels === 4) resized.data[destOffset + 3] = 255;
        }
    }

    canvas.width = destW;
    canvas.height = destH;

    const resizedContext = canvas.getContext('2d');

    if (!resizedContext) throw new Error('Could not generate resized context');

    resizedContext.clearRect(0, 0, canvas.width, canvas.height);

    resizedContext.putImageData(resized, 0, 0);

    const result = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 0.95);
    });

    if (!result) throw new Error('Could not generate resized image');

    return result;
};

export const loadImage = async (url: string): Promise<HTMLImageElement> => {
    const img = document.createElement('img');
    img.src = url;

    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    return img;
};

export const resizeImgUrl = async (url: string, size: number): Promise<Blob> => {
    const img = document.createElement('img');
    img.src = url;

    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    const canvas = document.createElement('canvas');
    const fullContext = canvas.getContext('2d');

    if (!fullContext) throw new Error('Unable to obtain image draw context');

    fullContext.drawImage(img, 0, 0);

    const xr = img.naturalWidth / size;
    const yr = img.naturalHeight / size;
    const r = xr > yr ? xr : yr;

    const [width, height] = [img.naturalWidth / r, img.naturalHeight / r];

    canvas.width = width;
    canvas.height = height;

    const resizedContenxt = canvas.getContext('2d');
    if (!resizedContenxt) throw new Error('Unable to draw resized context');
    resizedContenxt.drawImage(img, 0, 0, width, height);
    const result = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 0.95);
    });
    if (!result) throw new Error('Unable to obtain image blob from canvas.');

    return result;
};

const toPalette = (c: number) => Math.round(c / PALETTE_BLOCK) * PALETTE_BLOCK;

export const sampleUrlColor = async (url: string): Promise<number> => {
    const colors = await sampleUrlColors(url);
    return colors[0];
};

export const sampleUrlColors = async (url: string): Promise<number[]> => {
    const blob = await blobFromUrl(url);
    const img = await createImageBitmap(blob);

    const [width, height] = [img.width, img.height];

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const fullContext = canvas.getContext('2d');

    if (!fullContext) throw new Error('Unable to obtain image draw context');

    fullContext.drawImage(img, 0, 0, width, height);

    const data = fullContext.getImageData(0, 0, width, height);

    const colors: Record<number, number> = {};

    const ystep = Math.floor(height * SPACIAL_BLOCK);
    const xstep = Math.floor(width * SPACIAL_BLOCK);

    const vals: Record<number, number> = {};

    for (let y = ystep; y < height; y += ystep) {
        const yoffset = y * (width * 4);
        for (let x = xstep; x < width; x += xstep) {
            const i = yoffset + x * 4;
            const r = toPalette(data.data[i]);
            const g = toPalette(data.data[i + 1]);
            const b = toPalette(data.data[i + 2]);

            const rval = vals[r] ?? 50;
            const gval = vals[g] ?? 50;
            const bval = vals[b] ?? 50;

            const compound = toInt(r, g, b, 100);
            colors[compound] = (colors[compound] ?? 0) + rval + gval + bval;

            vals[r] = Math.max(rval - 10, 1);
            vals[g] = Math.max(gval - 10, 1);
            vals[b] = Math.max(bval - 10, 1);
        }
    }

    const idces = Object.keys(colors)
        .map((clr) => ({ clr: parseInt(clr), val: colors[parseInt(clr)] }))
        .sort((a, b) => b.val - a.val);

    return idces.map((it) => it.clr);
};

export const getImageSize = async (url: string) =>
    await new Promise<[number, number]>((resolve, reject) => {
        const img = document.createElement('img');
        img.onerror = reject;
        img.onload = () => resolve([img.naturalWidth, img.naturalHeight]);
        img.src = url;
    });

export const getImageData = async (url: string, size?: number) => {
    const img = await new Promise<HTMLImageElement>((resolve, reject) => {
        const img = document.createElement('img');
        img.onerror = reject;
        img.onload = () => resolve(img);
        img.src = url;
    });

    if (!size) {
        size = Math.max(img.naturalWidth, img.naturalHeight);
    }

    const max = Math.max(img.naturalWidth, img.naturalHeight);

    const [width, height] = [(size * img.naturalWidth) / max, (size * img.naturalHeight) / max];

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Cannot obtain canvas context');
    ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, width, height);

    return ctx.getImageData(0, 0, width, height);
};

export const getImagePixels = async (url: string, size?: number) => {
    const imgData = await getImageData(url, size);

    const toPixels = async (data: ImageData) => {
        return arrayOf(data.width * data.height).map(
            (_, idx) => (data.data[idx * 4] + data.data[idx * 4 + 1] + data.data[idx * 4 + 2]) / 3
        );
    };

    return {
        pixels: await toPixels(imgData),
        width: imgData.width,
        height: imgData.height,
    };
};
