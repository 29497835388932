import StoryRepo from '../../data/stories/StoryRepo';
import { FirebaseSourceProps, RepoProviderProps } from './lensinjector';

export const storyProviders = {
    getStories: {
        provider: ({ storyRepo }: { storyRepo: StoryRepo }) => storyRepo.getStories,
        single: false,
    },
    storyRepo: {
        provider: ({ firebaseSource, getUserId, getAnonId }: RepoProviderProps) =>
            new StoryRepo(firebaseSource, getUserId, getAnonId),
        single: true,
    },
    getCategories: {
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.getCategories,
        single: false,
    },
    createStory: {
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.createStory,
        single: false,
    },
    getStory: {
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.getStory,
        single: false,
    },
    getScene: {
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.getScene,
        single: false,
    },
    deleteStory: {
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.deleteStory,
        single: false,
    },
    updateStory: {
        single: false,
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.updateStory,
    },
    updateManifest: {
        single: false,
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.updateManifest,
    },
    uploadFile: {
        single: false,
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.uploadFile,
    },
    addTp: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.addTp,
        single: false,
    },
    addMyStory: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.addMyStory,
        single: false,
    },
    sendFeedback: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.sendFeedback,
        single: false,
    },
    getStoryCode: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.getStoryCode,
        single: false,
    },
    setStoryCode: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.setStoryCode,
        single: false,
    },
    getMyStories: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.getMyStories,
        single: false,
    },
    getMyStory: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.getMyStory,
        single: false,
    },
    getMyTps: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.getMyTps,
        single: false,
    },
    removeMyStory: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.removeMyStory,
        single: false,
    },
    resetMyStoryChats: {
        provider: ({ firebaseSource }: FirebaseSourceProps) => firebaseSource.resetMyStoryChats,
        single: false,
    },
    getAllSocialReports: {
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.getAllSocialReports,
        single: false,
    },
    getSocialReport: {
        provider: ({ storyRepo }: StoryRepoProps) => storyRepo.getSocialReport,
        single: false,
    },
};

interface StoryRepoProps {
    storyRepo: StoryRepo;
}
